import theme from '../styles/themes/common';

/**
 * Props disponíveis para o IInterUITimeline.
 */
export interface IInterUITimelineProps {
  /**
   * Título da timeline.
   */
  title?: string;
  /**
   * Variação da timeline.
   */
  type?: TimelineType;
  /**
   * ícone da timeline.
   */
  icon?: React.ReactNode;
  /**
   * Acrescenta a linha da timeline
   * @default false
   */
  hasVerticalBar?: boolean;
}

export type TimelineType =
  | 'Success'
  | 'Error'
  | 'Warning'
  | 'Disabled'
  | 'Start';

export type PropsWithRequiredChildren<T = unknown> = T & {
  children: import('react').ReactNode;
};

export const colorByType: Record<TimelineType, string> = {
  Success: theme.success.A500,
  Error: theme.error.A500,
  Warning: theme.alert.A500,
  Disabled: theme.grayscale.A300,
  Start: theme.grayscale.A500
};
