export const ACCEPTED_FILE_TYPES = [
  '.png',
  '.vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.msword',
  '.jpg,',
  '.jpeg',
  '.pdf',
  '.docx',
  '.doc'
];
