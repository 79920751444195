import common from './common';

/**
 * Paleta de cores referente ao tema do Localiza.
 */
export const LocalizaTheme = {
  title: 'localiza',
  name: 'Localiza',

  colors: {
    primary: {
      A500: '#00984a',
      A400: '#36ae70',
      A300: '#77c89f',
      A200: '#b7e2cc',
      A100: '#e5f5ed'
    },
    ...common
  }
};
