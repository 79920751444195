import React from 'react';
import { useTheme } from 'styled-components';

import { IInterUIButtonProps } from '../../interfaces/inter-ui-button-props';
import {
  Container,
  Primary,
  Secondary,
  SvgContainer,
  Tertiary
} from './InterUIButton.styles';
import { InterUILoading } from '../../components/InterUILoading/InterUILoading';

/**
 * Componente Inter UI Botão.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIButton: React.FC<
  IInterUIButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, children, loading, ...props }) => {
  const theme = useTheme();

  const renderButtonVariant = {
    primary: () => (
      <Primary {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading
              size={props.small ? 'xs' : undefined}
              halfColor={theme.colors.white}
              fullColor={
                props.disabled
                  ? theme.colors.grayscale.A400
                  : theme.colors.primary.A400
              }
            />
          </SvgContainer>
        ) : (
          children
        )}
      </Primary>
    ),
    secondary: () => (
      <Secondary {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading size={props.small ? 'xs' : undefined} />
          </SvgContainer>
        ) : (
          children
        )}
      </Secondary>
    ),
    tertiary: () => (
      <Tertiary {...props}>
        {loading ? (
          <SvgContainer role='status'>
            <InterUILoading size={props.small ? 'xs' : undefined} />
          </SvgContainer>
        ) : (
          children
        )}
      </Tertiary>
    )
  };

  const render = renderButtonVariant[props.variant || 'primary'];

  return (
    <Container
      data-testid='inter-ui-button'
      className={className}
      variant={props.variant}
      margin={props.margin}
    >
      {render()}
    </Container>
  );
};
