import React, { useState, useEffect } from 'react';

import useCalendar, { Column } from './useCalendar/useCalendar';
import ChevronLeftIcon from 'inter-frontend-svgs/lib/orangeds/MD/chevronleft';
import ChevronRightIcon from 'inter-frontend-svgs/lib/orangeds/MD/chevronright';
import { useTheme } from 'styled-components';
import {
  ColumnDays,
  Day,
  Days,
  Header,
  HeaderWeekDays,
  Table,
  WeekDay,
  WeekDays
} from './InterUINewCalendar.styles';
import { InterUIBox } from '..';
import { IInterUINewCalendarProps } from '../../interfaces/inter-ui-new-calendar-props';

export const InterUINewCalendar: React.FC<IInterUINewCalendarProps> = ({
  prevRange,
  nextRange,
  availableDates = [],
  date,
  isLoading = false,
  loading,
  margin,
  scheduledDate = () => {},
  handleNavigationClick = () => {}
}) => {
  const {
    calendarRows,
    selectedDate,
    today,
    todayFormatted,
    daysShort,
    monthNames,
    getNextMonth,
    getPrevMonth,
    prevButtonDisabled,
    nextButtonDisabled
  } = useCalendar(prevRange, nextRange, date);

  const theme = useTheme();

  const [selectedDay, setSelectedDay] = useState('');

  useEffect(() => {
    setSelectedDay('');
    handleNavigationClick(selectedDate);
  }, [selectedDate]);

  const dateClickHandler = (date: string) => {
    setSelectedDay(date);
    scheduledDate(date);
  };

  return (
    <InterUIBox direction='column' margin={margin}>
      <Header>
        <ChevronLeftIcon
          data-testid='icon-previous'
          onClick={!isLoading ? getPrevMonth : undefined}
          color={
            prevButtonDisabled || isLoading
              ? theme.colors.grayscale.A200
              : theme.colors.primary.A500
          }
        />
        {monthNames[selectedDate.getMonth()]}
        <ChevronRightIcon
          data-testid='icon-next'
          onClick={!isLoading ? getNextMonth : undefined}
          color={
            nextButtonDisabled || isLoading
              ? theme.colors.grayscale.A200
              : theme.colors.primary.A500
          }
        />
      </Header>
      {!isLoading ? (
        <Table>
          <HeaderWeekDays>
            <WeekDays>
              {daysShort.map((day) => (
                <WeekDay key={day}>{day}</WeekDay>
              ))}
            </WeekDays>
          </HeaderWeekDays>
          <Days>
            {Object.values(calendarRows).map((cols: Column[]) => {
              return (
                <ColumnDays key={cols[0].date}>
                  {cols.map((col) =>
                    col.date === todayFormatted ? (
                      <Day
                        key={col.value}
                        className={`${col.classes} ${
                          selectedDay === col.date
                            ? 'selectedDay'
                            : availableDates.includes(col.date) &&
                              selectedDate.getMonth() === today?.getMonth()
                            ? 'today'
                            : 'inactiveToday'
                        }`}
                        onClick={() =>
                          availableDates.includes(col.date) &&
                          dateClickHandler(col.date)
                        }
                      >
                        {col.classes === '' && col.value}
                      </Day>
                    ) : (
                      <Day
                        key={col.value}
                        className={`${col.classes} ${
                          selectedDay === col.date ? 'selectedDay' : ''
                        }`}
                        style={
                          availableDates.includes(col.date)
                            ? { color: theme.colors.grayscale.A500 }
                            : { color: theme.colors.grayscale.A200 }
                        }
                        onClick={() =>
                          col.classes === '' &&
                          availableDates.includes(col.date)
                            ? dateClickHandler(col.date)
                            : undefined
                        }
                      >
                        {col.classes === '' && col.value}
                      </Day>
                    )
                  )}
                </ColumnDays>
              );
            })}
          </Days>
        </Table>
      ) : (
        loading
      )}
    </InterUIBox>
  );
};

export default InterUINewCalendar;
