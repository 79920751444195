import React, { useState, useEffect } from 'react';
import {
  IMonth,
  IInterUICalendarProps
} from '../../interfaces/inter-ui-calendar-props';
import {
  Month,
  Calendar,
  Weekdays,
  Days,
  Container,
  Loading,
  DayOfTheWeek,
  AvailableDays,
  InactiveDays,
  BlankBlock,
  NameDayOfTheWeek
} from './InterUICalendar.styles';
import ChevronLeftIcon from 'inter-frontend-svgs/lib/orangeds/MD/chevronleft';
import ChevronRightIcon from 'inter-frontend-svgs/lib/orangeds/MD/chevronright';
import { useTheme } from 'styled-components';
import { InterUILoading } from '..';

const listOfMonths = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

let initialMonth = {
  monthName: '',
  dayMonth: 1,
  year: 0
};

/**
 * Componente Inter UI Calendar.
 */
export const InterUICalendar: React.FC<IInterUICalendarProps> = ({
  datesAvailableInMonth,
  handleAvailableDatesInTheMonth,
  startingMonth,
  finalMonth,
  selectedDate,
  appointmentDate = () => {},
  scheduledDate,
  isLoading,
  margin
}) => {
  const today = new Date();
  const theme = useTheme();
  const dates = datesAvailableInMonth.map((date) => new Date(date));
  const [selectedMonth, setSelectedMonth] = useState<IMonth>(initialMonth);
  const [nameSelectedMonth, setNameSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState(0);
  const [markedDay, setMarkedDay] = useState(0);
  const [markedMonth, setMarkedMonth] = useState(0);
  const [disabledNextMonth, setDisabledNextMonth] = useState<boolean>(false);
  const [disabledPreviousMonth, setDisabledPreviousMonth] = useState<boolean>(
    true
  );

  useEffect(() => {
    if (startingMonth) {
      const date = new Date(startingMonth);
      initialMonth = {
        monthName: listOfMonths[date.getMonth()],
        dayMonth: date.getMonth() + 1,
        year: date.getFullYear()
      };

      if (finalMonth) {
        const month = new Date(finalMonth).getMonth() + 1;
        const year = new Date(finalMonth).getFullYear();

        if (month === initialMonth.dayMonth && year === initialMonth.year) {
          setDisabledNextMonth(true);
        }
      }

      setSelectedMonth(initialMonth);
      setNameSelectedMonth(listOfMonths[date.getMonth()]);
    } else {
      initialMonth = {
        monthName: listOfMonths[today.getMonth()],
        dayMonth: today.getMonth() + 1,
        year: today.getFullYear()
      };
      setSelectedMonth(initialMonth);
      setNameSelectedMonth(listOfMonths[today.getMonth()]);
    }

    if (scheduledDate) {
      const markedDate = new Date(scheduledDate);
      const formattedDate = `${('0' + markedDate.getDate()).slice(-2)}/${(
        '0' +
        (markedDate.getMonth() + 1)
      ).slice(-2)}/${markedDate.getFullYear()}`;

      setMarkedDay(markedDate.getDate());
      setMarkedMonth(markedDate.getMonth() + 1);

      if (markedDate.getMonth() + 1 === initialMonth.dayMonth) {
        appointmentDate(formattedDate);
      }
    }
  }, []);

  useEffect(() => {
    if (datesAvailableInMonth.length >= 0) {
      if (finalMonth) {
        const final = new Date(finalMonth).getMonth() + 1;

        if (final === selectedMonth.dayMonth) {
          setDisabledNextMonth(true);
        }
      }
    }
  }, [datesAvailableInMonth]);

  /**
   * Função responsável por controlar a navegação dos meses.
   * @returns navegação dos meses.
   */
  const navBarMonths = () => {
    const handleClickAvailableMonths = (month: any) => {
      setNameSelectedMonth(month.monthName);
      setSelectedMonth(month);
      setSelectedDay(0);
      selectedDate('');
    };

    const previousMonth = () => {
      if (!isLoading && !disabledPreviousMonth) {
        setDisabledNextMonth(false);

        // Voltar ao mês anterior do ano atual
        if (
          initialMonth.dayMonth === selectedMonth.dayMonth - 1 &&
          selectedMonth.year === today.getFullYear()
        ) {
          setDisabledPreviousMonth(true);
        }

        // Quando o mês inicial é Dezembro.
        if (
          initialMonth.dayMonth === 12 &&
          selectedMonth.dayMonth === 1 &&
          selectedMonth.year !== today.getFullYear()
        ) {
          setDisabledPreviousMonth(true);
        }

        // Seleciona o mês anterior do ano atual.
        if (
          selectedMonth.dayMonth <= 12 &&
          selectedMonth.year === today.getFullYear()
        ) {
          const month = {
            monthName: listOfMonths[selectedMonth.dayMonth - 2],
            dayMonth: selectedMonth.dayMonth - 1,
            year: today.getFullYear()
          };

          handleAvailableDatesInTheMonth(month);
          handleClickAvailableMonths(month);
        }

        // Seleciona o mês anterior de janeiro do ano seguinte.
        // e vai para dezembro do ano atual.
        if (
          selectedMonth.dayMonth === 1 &&
          selectedMonth.year !== today.getFullYear()
        ) {
          const month = {
            monthName: listOfMonths[11],
            dayMonth: 12,
            year: today.getFullYear()
          };

          handleAvailableDatesInTheMonth(month);
          handleClickAvailableMonths(month);
        }

        // Seleciona o mês anterior do ano seguinte.
        if (
          selectedMonth.dayMonth !== 1 &&
          selectedMonth.dayMonth <= 12 &&
          selectedMonth.year !== today.getFullYear()
        ) {
          const month = {
            monthName: listOfMonths[selectedMonth.dayMonth - 2],
            dayMonth: selectedMonth.dayMonth - 1,
            year: today.getFullYear() + 1
          };

          handleAvailableDatesInTheMonth(month);
          handleClickAvailableMonths(month);
        }

        if (scheduledDate) {
          const markedDate = new Date(scheduledDate);
          const formattedDate = `${('0' + markedDate.getDate()).slice(-2)}/${(
            '0' +
            (markedDate.getMonth() + 1)
          ).slice(-2)}/${markedDate.getFullYear()}`;

          if (markedDate.getMonth() + 1 === selectedMonth.dayMonth - 1) {
            appointmentDate(formattedDate);
          } else {
            appointmentDate('');
          }
        }
      }
    };

    const nextMonths = () => {
      if (!isLoading && !disabledNextMonth) {
        setDisabledPreviousMonth(false);

        let month = {
          monthName: 'Janeiro',
          dayMonth: 1,
          year: initialMonth.year + 1
        };

        // Começar em fevereiro e terminar em Janeiro.
        if (
          initialMonth.dayMonth === 2 &&
          selectedMonth.dayMonth === 12 &&
          selectedMonth.year === today.getFullYear()
        ) {
          setDisabledNextMonth(true);
        }

        // Começar em Janeiro e terminar em Dezembro.
        if (
          initialMonth.dayMonth === 1 &&
          selectedMonth.dayMonth === 11 &&
          selectedMonth.year === today.getFullYear()
        ) {
          setDisabledNextMonth(true);
        }

        // Começar em um mês maior que fevereiro e terminar no mês anterior
        // do ano seguinte.
        if (
          initialMonth.dayMonth - 1 === selectedMonth.dayMonth + 1 &&
          selectedMonth.year !== today.getFullYear()
        ) {
          setDisabledNextMonth(true);
        }

        // Seleciona o próximo mês do ano atual.
        if (
          selectedMonth.dayMonth < 12 &&
          selectedMonth.year === today.getFullYear()
        ) {
          month = {
            monthName: listOfMonths[selectedMonth.dayMonth],
            dayMonth: selectedMonth.dayMonth + 1,
            year: today.getFullYear()
          };
        }

        // Seleciona o próximo mês do ano seguinte.
        if (
          initialMonth.dayMonth - 1 > selectedMonth.dayMonth &&
          selectedMonth.year !== today.getFullYear()
        ) {
          month = {
            monthName: listOfMonths[selectedMonth.dayMonth],
            dayMonth: selectedMonth.dayMonth + 1,
            year: selectedMonth.year
          };
        }

        handleAvailableDatesInTheMonth(month);
        handleClickAvailableMonths(month);

        if (scheduledDate) {
          const markedDate = new Date(scheduledDate);
          const formattedDate = `${('0' + markedDate.getDate()).slice(-2)}/${(
            '0' +
            (markedDate.getMonth() + 1)
          ).slice(-2)}/${markedDate.getFullYear()}`;

          if (markedDate.getMonth() === selectedMonth.dayMonth) {
            appointmentDate(formattedDate);
          } else {
            appointmentDate('');
          }
        }
      }
    };

    return (
      <Month>
        <ChevronLeftIcon
          data-testid='icon-previous'
          color={
            isLoading || disabledPreviousMonth
              ? theme.colors.grayscale.A200
              : theme.colors.primary.A500
          }
          onClick={() => previousMonth()}
        />
        <NameDayOfTheWeek>{nameSelectedMonth}</NameDayOfTheWeek>
        <ChevronRightIcon
          data-testid='icon-next'
          color={
            isLoading || disabledNextMonth
              ? theme.colors.grayscale.A200
              : theme.colors.primary.A500
          }
          onClick={() => nextMonths()}
        />
      </Month>
    );
  };

  /**
   * Função responsável por obter os dias da semana do mês.
   * @param month número do mês.
   * @param year ano do mês.
   * @returns dias da semana(Seg à Dom).
   */
  const getWeekDaysInMonth = (month: number, year: number) => {
    return new Array(new Date(year, month + 1, 0).getDate())
      .fill('')
      .map((_n, i) => {
        return i + 1;
      })
      .filter((val) => !!val);
  };

  /**
   * Função responsável por renderizar os dias da semana.
   * @returns os dias da semana.
   */
  const calendar = () => {
    const rowsEmpty: any[] = [];
    const rowsWeekDaysInMonth: any[] = [];
    const weekdayIndex: any[] = [];
    const date = new Date(`${selectedMonth.year}/${selectedMonth.dayMonth}/01`);
    const weekDaysInMonth = getWeekDaysInMonth(
      selectedMonth.dayMonth - 1,
      selectedMonth.year
    );
    const availableDaysInMonth = dates
      .filter((date) => date.getMonth() === selectedMonth.dayMonth - 1)
      .map((date) => date.getDate());

    if (date.getDay() === 0) {
      for (let i = 1; i <= 6; i++) {
        rowsEmpty.push(<BlankBlock key={i} />);
      }
    }

    if (date.getDay() < 7) {
      for (let i = 1; i <= date.getDay() - 1; i++) {
        rowsEmpty.push(<BlankBlock key={i} />);
      }
    }

    availableDaysInMonth.map((_item, index) => {
      weekdayIndex.push(weekDaysInMonth.indexOf(availableDaysInMonth[index]));
    });

    weekDaysInMonth.map((days, index) =>
      rowsWeekDaysInMonth.push(
        <InactiveDays
          key={index}
          className={
            (!scheduledDate &&
              today.getDate() === days &&
              selectedMonth.dayMonth === today.getMonth() + 1) ||
            (scheduledDate &&
              new Date(scheduledDate).getMonth() + 1 ===
                selectedMonth.dayMonth &&
              new Date(scheduledDate).getDate() === days)
              ? 'inactiveToday'
              : 'inactiveDays'
          }
        >
          {days}
        </InactiveDays>
      )
    );

    const handleClickAvailableDays = (day: number) => {
      const formattedDate = `${('0' + day).slice(-2)}/${(
        '0' + selectedMonth.dayMonth
      ).slice(-2)}/${selectedMonth.year}`;
      setSelectedDay(day);
      selectedDate(formattedDate);
    };

    availableDaysInMonth.map(
      (availableDay, index) =>
        (rowsWeekDaysInMonth[weekdayIndex[index]] = (
          <AvailableDays
            key={weekdayIndex[index]}
            onClick={() => handleClickAvailableDays(availableDay)}
            className={
              selectedDay === availableDay
                ? 'selectedDay'
                : selectedMonth.monthName === listOfMonths[today.getMonth()] &&
                  today.getDate() === availableDay &&
                  markedDay === 0
                ? 'today'
                : markedDay === availableDay &&
                  markedMonth === selectedMonth.dayMonth
                ? 'today'
                : ''
            }
          >
            {availableDay}
          </AvailableDays>
        ))
    );

    return (
      <Calendar>
        <Weekdays>
          <DayOfTheWeek>Seg</DayOfTheWeek>
          <DayOfTheWeek>Ter</DayOfTheWeek>
          <DayOfTheWeek>Qua</DayOfTheWeek>
          <DayOfTheWeek>Qui</DayOfTheWeek>
          <DayOfTheWeek>Sex</DayOfTheWeek>
          <DayOfTheWeek>Sáb</DayOfTheWeek>
          <DayOfTheWeek>Dom</DayOfTheWeek>
        </Weekdays>
        <Days>
          {rowsEmpty}
          {rowsWeekDaysInMonth}
        </Days>
      </Calendar>
    );
  };

  return (
    <Container margin={margin}>
      {navBarMonths()}
      {isLoading ? (
        <Loading>
          <InterUILoading size='ld' />
        </Loading>
      ) : (
        calendar()
      )}
    </Container>
  );
};
