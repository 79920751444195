/**
 * Paleta de cores comuns entre os temas disponíveis.
 */
const white = '#ffffff';

const grayscale = {
  A500: '#161616',
  A400: '#6a6c72',
  A300: '#b6b7bb',
  A200: '#dedfe4',
  A100: '#f5f6fa'
};

const success = {
  A500: '#36ae70',
  A100: '#e9f8f0'
};

const alert = {
  A500: '#ffba00',
  A400: '#ffc700',
  A100: '#fff7d9'
};

const error = {
  A500: '#ff6227',
  A400: '#f56a50',
  A100: '#fef0ed'
};

export default {
  white,
  grayscale,
  success,
  alert,
  error
};
