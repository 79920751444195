import styled from 'styled-components';

export const Month = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Calendar = styled.div`
  width: 100%;
`;

export const Weekdays = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
`;

export const DayOfTheWeek = styled.p`
  font-size: 12px;
  font-weight: 700;
  width: calc(100% / 7);
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Div principal.
 */
export const Container = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.grayscale.A200};
  border-radius: 16px;
  flex-direction: column;

  ${(props) => props.margin && `margin: ${props.margin};`}
`;

export const Days = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  position: relative;

  div {
    font-size: 12px;
    font-weight: 700;
    width: calc(100% / 7);
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selectedDay {
    color: ${(props) => props.theme.colors.grayscale.A500};
  }

  div.selectedDay::before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary.A500};
    z-index: -999;
  }

  .inactiveDays {
    color: ${(props) => props.theme.colors.grayscale.A200};
  }

  .inactiveToday {
    color: ${(props) => props.theme.colors.white};
  }

  .inactiveToday::before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.grayscale.A200};
    z-index: -999;
  }

  .today {
    color: ${(props) => props.theme.colors.white};
  }

  .today::before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.grayscale.A500};
    z-index: -999;
  }
`;

export const InactiveDays = styled.div``;
export const AvailableDays = styled.div``;
export const BlankBlock = styled.div``;

export const NameDayOfTheWeek = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

export const Loading = styled.div`
  height: 238px;
  display: flex;
  align-items: center;
`;
