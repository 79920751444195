import React from 'react';
import { useTheme } from 'styled-components';

import { IInterUICheckBoxProps } from '../../interfaces/inter-ui-check-box-props';
import {
  Container,
  CheckboxContent,
  LabelContent,
  HiddenCheckbox,
  StyledCheckbox
} from './InterUICheckBox.styles';
import { InterUIIcon } from '../InterUIIcon/InterUIIcon';

type InputProps = IInterUICheckBoxProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

/**
 * Componente Inter UI CheckBox.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUICheckBox = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, margin, children, ref, ...props }, register) => {
    const theme = useTheme();

    return (
      <Container
        data-testid='inter-ui-checkbox'
        htmlFor={props.id}
        checked={props.checked}
        disabled={props.disabled}
        variant={variant}
        margin={margin}
        className={className}
      >
        <LabelContent disabled={props.disabled} variant={variant}>
          {children}
        </LabelContent>

        <CheckboxContent>
          <HiddenCheckbox
            ref={register}
            data-testid='inter-ui-checkbox-hidden'
            {...props}
          />

          <StyledCheckbox>
            <InterUIIcon name='check' size='12px' color={theme.colors.white} />
          </StyledCheckbox>
        </CheckboxContent>
      </Container>
    );
  }
);
