import styled from 'styled-components';

/**
 * Div principal.
 */
export const Container = styled.div<{ margin?: string }>`
  ${(props) => props.margin && `margin: ${props.margin};`}

  .group-search {
    position: relative;

    .inter-ic-search {
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: translateX(-60px);
    }

    .inter-ic-contextual-error {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto;

      &:hover {
        cursor: pointer;
      }
    }

    input {
      text-align: center;
    }
  }
`;

/**
 * Label a ser exibida acima do dropdown.
 */
export const Label = styled.div<{
  disabled: boolean;
  useHelperButton?: boolean;
}>`
  ${(props) => props?.useHelperButton && `display: grid;`}
  ${(props) => props?.useHelperButton && `grid-template-columns: 1fr 1fr;`}
  margin: 0 0 4px 2px;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.grayscale.A300
      : props.theme.colors.grayscale.A400};
`;

/**
 * Div para dropdown em formato de select.
 */
export const DropdownFake = styled.div<{
  disabled: boolean;
  placeholder: string | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.colors.grayscale.A100};
  border-radius: 8px;

  p {
    width: 100%;
    margin-right: 4px;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${(props) =>
      (props.disabled && `color: ${props.theme.colors.grayscale.A200}`) ||
      (props.placeholder && `color: ${props.theme.colors.grayscale.A300}`)}
  }
`;

/**
 * Div container para o loading.
 */
export const Loading = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
`;

/**
 * Div container para lista de opções exibidas no bottom sheet.
 */
export const ContentOptions = styled.div<{
  contentHeight?: string;
  hideSearch?: boolean;
}>`
  height: ${(props) =>
    props.contentHeight || (props.hideSearch ? `68vh` : `55vh`)};

  max-height: ${(props) => (props.hideSearch ? `68vh` : `55vh`)};
  overflow: auto;
`;

/**
 * Div para o item das opções exibidas no bottom sheet.
 */
export const Option = styled.div<{ truncateOption?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.grayscale.A200}`};

  p {
    width: 100%;
    margin-right: 8px;
    font-size: 0.75rem;
    line-height: 0.938rem;

    ${(props) =>
      props.truncateOption &&
      `overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;`};
  }
`;

/**
 * Botão auxiliar do dropdown.
 */
export const Button = styled.button`
  align-items: center;
  font-family: Inter;
  font-size: 0.75rem;
  line-height: 0.938rem;
  font-style: normal;
  font-weight: normal;
  text-align: right;
  color: ${(props) => props.theme.colors.primary.A500};
`;
