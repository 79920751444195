import React from 'react';
import { useTheme } from 'styled-components';

import { IInterUIAlertProps } from '../../interfaces/inter-ui-alert-props';
import { Container, Content, Icon } from './InterUIAlert.styles';
import { InterUIIcon } from '../../core/InterUIIcon/InterUIIcon';

/**
 * Componente Inter UI Alert.
 * @param props Propriedades disponíveis para definição do layout.
 */
export const InterUIAlert: React.FC<
  IInterUIAlertProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ margin, type, children, color, icon, ...props }) => {
  const theme = useTheme();

  const renderIconType = () => {
    switch (type) {
      case 'error':
        return (
          <InterUIIcon
            name='contextual-error'
            size='sm'
            color={theme.colors.error.A400}
          />
        );
      case 'attention':
        return (
          <InterUIIcon
            name='contextual-attention'
            size='sm'
            color={theme.colors.alert.A400}
          />
        );
      case 'security':
        return (
          <InterUIIcon
            name='security'
            size='sm'
            color={theme.colors.grayscale.A500}
          />
        );
      default:
        return (
          <InterUIIcon
            name='contextual-info'
            size='sm'
            color={theme.colors.grayscale.A300}
          />
        );
    }
  };

  return (
    <Container
      margin={margin}
      type={type}
      color={color}
      data-testid='inter-ui-alert'
      {...props}
    >
      <Icon data-testid='inter-ui-alert-icon'>{icon || renderIconType()}</Icon>
      <Content>{children}</Content>
    </Container>
  );
};
