import styled from 'styled-components';

/**
 * Div principal.
 */
export const Container = styled.nav`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 14px 24px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 10;
`;

/**
 * Cabeçalho central.
 */
export const Headline = styled.h3`
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
`;

/**
 * Container para o ícone exibido nas extremidades.
 */
export const Icon = styled.div`
  min-width: 28px;
  min-height: 28px;

  button {
    display: flex;
    align-items: center;
    width: 28px;
    height: 28px;
  }
`;

/**
 * Bloco invisível utilizado após a navbar para preencher o espaço abaixo do fixed.
 */
export const InvisibleBlock = styled.div`
  height: 56px;
`;
