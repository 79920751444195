/**
 * Paleta de cores auxiliares do inter.
 */
const grape = {
  A500: '#512DA8',
  A400: '#7E57C2',
  A300: '#B39DDB',
  A200: '#D1C4E9',
  A100: '#EDE7F6'
};

const mirtilo = {
  A500: '#303F9F',
  A400: '#5C6BC0',
  A300: '#9FA8DA',
  A200: '#C5CAE9',
  A100: '#E8EAF6'
};

const raspberry = {
  A500: '#0277BD',
  A400: '#039BE5',
  A300: '#4FC3F7',
  A200: '#B3E5FC',
  A100: '#E1F5FE'
};

const avocado = {
  A500: '#00796B',
  A400: '#26A69A',
  A300: '#80CBC4',
  A200: '#B2DFDB',
  A100: '#DDF0EF'
};

const lime = {
  A500: '#33691E',
  A400: '#7CB342',
  A300: '#AED581',
  A200: '#DCEDC8',
  A100: '#F1F8E9'
};

const pitaya = {
  A500: '#C2185B',
  A400: '#EC407A',
  A300: '#F48FB1',
  A200: '#F8BBD0',
  A100: '#FCE4EC'
};

const strawberry = {
  A500: '#D32F2F',
  A400: '#EF5350',
  A300: '#EF9A9A',
  A200: '#FFCDD2',
  A100: '#FFEBEE'
};

const toranja = {
  A500: '#D84315',
  A400: '#FF7043',
  A300: '#FFAB91',
  A200: '#FFCCBC',
  A100: '#FBE9E7'
};

const coconut = {
  A500: '#5D4037',
  A400: '#795548',
  A300: '#A1887F',
  A200: '#D7CCC8',
  A100: '#EFEBE9'
};

const pear = {
  A500: '#9E9D24',
  A400: '#C0CA33',
  A300: '#DCE775',
  A200: '#F0F4C3',
  A100: '#F9FBE7'
};

export default {
  grape,
  mirtilo,
  raspberry,
  avocado,
  lime,
  pitaya,
  strawberry,
  toranja,
  coconut,
  pear
};
