import styled, { css } from 'styled-components';

interface PropsStyle {
  margin?: string;
  toggle?: boolean;
  border?: 'top' | 'bottom' | 'both' | 'none';
  transitionIn?: string;
  transitionOut?: string;
}

/**
 * Div principal.
 */
export const Container = styled.div<PropsStyle>`
  overflow: hidden;

  ${(props) => props.margin && `margin: ${props.margin};`}

  ${(props) =>
    props.border !== 'none' &&
    `border: 0 solid ${props.theme.colors.grayscale.A200};`}

  ${(props) => props.border === 'top' && `border-top-width: 1px;`}
  ${(props) => props.border === 'bottom' && `border-bottom-width: 1px;`}
  ${(props) => props.border === 'both' && `border-width: 1px 0 1px 0;`}
`;

/**
 * Div para o cabeçalho.
 */
export const Header = styled.h2``;

/**
 * Botão principal onde haverá o clique para exibir/ocultar o conteúdo.
 */
export const HeaderBtn = styled.button<PropsStyle>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Inter';
  font-size: 0.875rem;
  line-height: 1.063rem;
  font-weight: bold;
  text-align: left;

  .collapse-arrow {
    margin: 0 1px 0 auto;
    transition: all 0.35s;

    ${(props) => props.toggle && `transform: rotate(180deg);`}
  }
`;

/**
 * Conteúdo do collapse.
 */
export const collapsedStyle = css<PropsStyle>`
  height: auto;
  max-height: 100vh;
  margin-bottom: 16px;
  ${(props) => props.transitionOut && `transition: ${props.transitionOut}`}
`;

export const Collapse = styled.div<PropsStyle>`
  max-height: 0;
  transition: ${(props) =>
    props.transitionIn ? props.transitionIn : '0.35s ease-out'};
  ${(props) => props.toggle && collapsedStyle}
`;
