import styled, { css } from 'styled-components';

import {
  TimelineType,
  colorByType
} from '../../interfaces/inter-ui-timeline-props';

type TypeProps = {
  type: TimelineType;
};

export const TimelineContainer = styled.ul``;

export const TimelineItemContainer = styled.li`
  list-style: none;
  display: flex;
  position: relative;
  margin: 0 0 2px;
  list-style: none;
`;

export const CustomIconContainer = styled.div`
  ${({ type }: TypeProps) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 2px solid ${colorByType[type]};
    border-radius: 50%;
  `}
`;

export const Dot = styled.span`
  ${({ type }: TypeProps) => css`
    position: absolute;
    ${type !== 'Start' && `height: 16px`};
    ${type !== 'Start' && `width: 16px`};
    ${type === 'Start' && `left: 6px`};
    ${type === 'Start' && `top: 6px`};
    ${type === 'Start'
      ? `border: 4px solid ${colorByType[type]}`
      : `border: 2px solid ${colorByType[type]}`};
    border-radius: 50%;
    display: inline-block;
  `}
`;

export const VerticalBar = styled.div`
  ${({ type }: TypeProps) => css`
    position: absolute;
    top: 22px;
    left: 9px;
    background-color: ${colorByType[type]};
    border-radius: 1px;
    width: 2px;
    height: calc(100% - 22px);
  `}
`;

export const Text = styled.span<{ color: string; bold?: boolean }>`
  font-family: 'Inter';
  font-size: 12px;
  margin: 0;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.grayscale.A400};
  ${(props) => props.bold && `font-weight: bold;`}
`;
