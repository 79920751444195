import common from './common';

/**
 * Paleta de cores referente ao tema do São Paulo.
 */
export const SaoPauloTheme = {
  title: 'sao-paulo',
  name: 'São Paulo',

  colors: {
    primary: {
      A500: '#c5232a',
      A400: '#d04f54',
      A300: '#de8488',
      A200: '#ecb8ba',
      A100: '#f9e9ea'
    },
    ...common
  }
};
