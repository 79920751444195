import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 14px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const HeaderWeekDays = styled.thead`
  width: 100%;
  display: flex;
`;

export const WeekDays = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const WeekDay = styled.th`
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
`;

export const Days = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ColumnDays = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Day = styled.td`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;

  &.today {
    color: ${(props) => props.theme.colors.white};
    position: relative;
  }

  &.today::before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.grayscale.A500};
    z-index: -2;
  }

  &.inactiveToday {
    color: ${(props) => props.theme.colors.white};
    position: relative;
  }

  &.inactiveToday::before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.grayscale.A200};
    z-index: -2;
  }

  &.selectedDay {
    color: ${(props) => props.theme.colors.grayscale.A500};
    position: relative;
  }

  &.selectedDay::before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary.A500};
    z-index: -2;
  }
`;

export const Loading = styled.div`
  height: 212px;
  display: flex;
  align-items: center;
`;
