import common from './common';
import auxColors from './inter-auxiliary-colors';

/**
 * Paleta de cores referente ao tema do Inter.
 */
export const InterTheme = {
  title: 'inter',
  name: 'Inter',

  colors: {
    primary: {
      A500: '#ff7a00',
      A400: '#ff9d42',
      A300: '#ffb46e',
      A200: '#ffdab7',
      A100: '#fff2e7'
    },
    ...common,
    ...auxColors
  }
};
